import type { ComponentParams, ComponentRendering, Field } from "@sitecore-jss/sitecore-jss-nextjs";
import { Placeholder } from "@sitecore-jss/sitecore-jss-nextjs";
import { Box, Container, Stack } from "@chakra-ui/react";
import SCHeading from "components/sc/SCHeading";
import SCText from "components/sc/SCText";

interface Fields {
    AnchorId: Field<string>;
    Headline: Field<string>;
    SubHeadline: Field<string>;
}

export type ContentProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: Fields;
};

const Content = (props: ContentProps) => {
    const phKeyImage = `Content-Image-${props.params?.DynamicPlaceholderId}`;
    const phKeyRichText = `Content-RichText-${props.params?.DynamicPlaceholderId}`;

    if (!props.fields) return null;
    return (
        <Container
            as="section"
            id={props.fields?.AnchorId.value}
            variant={"module"}
            sx={{
                "+ div": {
                    pt: "0",
                },
            }}
            className="content-container"
            {...(props.fields.Headline.value !== "" && {
                "aria-labelledby": props.rendering.uid ?? props.rendering.componentName,
            })}
            display="grid"
            gap="sp40"
            gridTemplateColumns={{ base: "1fr", xl: "1fr 1fr" }}
        >
            <Box>
                <SCHeading
                    id={props.rendering.uid ?? props.rendering.componentName}
                    as="h2"
                    size="lg"
                    fontWeight="light"
                    lineHeight="shorter"
                    mb="sp40"
                    textField={props.fields?.Headline}
                />

                <SCText as="p" size="bodyXl" mb="sp64" textField={props.fields?.SubHeadline} />
                <Box mb="sp40">
                    <Placeholder name={phKeyImage} rendering={props.rendering} />
                </Box>
            </Box>
            <Stack spacing={"sp64"}>
                <Placeholder name={phKeyRichText} rendering={props.rendering} />
            </Stack>
        </Container>
    );
};

export default Content;
